import { post } from "@/utils/request";

export const fetchSomeoneRecentVisitList = async (data) => {
  return await post("/appdealer/api/saleman/visitList", data);
};

export const fetchBlankVillageVisitList = async (data) => {
  return await post(
    "/appdealer/api/blankVillage/get_blankVillageVisitList",
    data
  );
};

// 史丹利
export const fetchBigFarmersVisitList = async (data) => {
  return await post("/appdealer/api/bigfarmers/get_bigFarmersVisitList", data);
};

export const fetchPotentialVisitList = async (data) => {
  return await post("/appdealer/api/potential/visit/get_record", data);
};

export const fetchModelfieldList = async (data) => {
  return await post("/appdealer/api/modelfield/visit/get_record", data);
};

export const fetchTerminalVisitList = async (data) => {
  return await post("/appdealer/api/terminal/visit/tip/get_record", data);
};

// 自有
export const fetchDistBigFarmersVisitList = async (data) => {
  return await post("/appdealer/api/dist/bigfarmers/visit/list", data);
};

export const fetchDistPotentialVisitList = async (data) => {
  return await post("/appdealer/api/dist/potential/visit/list", data);
};

export const fetchDistModelfieldList = async (data) => {
  return await post("/appdealer/api/dist/modelfield/visit/get_record", data);
};

export const fetchDistTerminalVisitList = async (data) => {
  return await post("/appdealer/api/dist/terminal/visit/tip/list", data);
};